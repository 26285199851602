h1.title {
    @extend .header-txt-shadow;
}

.subtitle {
    font-weight: normal;
    margin-top: 5px;
    text-shadow: 1px 1px 2px $text-shadow;
}

//Shared css in _post.scss
