.search-bar {
    display: block;
    width: 100%;
    height: 46px;
    padding: 6px 12px;
    font-size: 18px;
    line-height: 1.43;
    color: $text-color;
    background: $background-color none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus,
    &:hover {
        border-color: $link-color;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $link-shadow;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $link-shadow;
    }

    &::-moz-placeholder {
        color: $search-color;
        opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
        color: $search-color;
    }

    input {
        &[type=text] {
            height: 46px;
            padding: 10px 16px;
            font-size: 18px;
            line-height: 1.33;
            border-radius: 6px;
        }
    }
}

.search-title a {
    h3 {
        margin: 0;
    }
    &:hover {
        text-decoration: none;
    }
}
